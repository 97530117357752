declare global {
  interface Array<T> {
    groupBy<K>(mapper: (item: T) => K, equalityFunction?: (a: K, b: K) => boolean): Array<{ key: K; items: Array<T> }>;
    last(): T;
    first(): T;
    leftJoin<T, TOuter, TKey, TResult>(
      outerArray: TOuter[],
      innerFn: (item: T) => TKey,
      outerFn: (item: TOuter) => TKey,
      projectionFn: (inner: T, outer: TOuter) => TResult,
    ): TResult[];
    immutableUpsert<TKey>(keyFn: (item: T) => TKey, newItem: T): T[];
    immutableUpsertByRef<TKey>(cmpFn: (item: T) => boolean, newItem: T): T[];
    immutableDelete(findFn: (item: T) => boolean): T[];
    immutableDeleteByIndex(i: number): T[];
    immutableReplaceByIndex(index: number, newItem: T): T[];
    toMap<TKey>(keyFn: (item: T) => TKey): Map<TKey, T[]>;
    toObject<K>(mapFn: (item: T) => { key: string; value: K }): { [key: string]: K };
    toObjectNumberKey<K extends number | string>(mapFn: (item: T) => { key: number; value: T }): { [key: number]: T };
    immutableUpsert(selectorFn: (item: T) => boolean, newValue: T): T[];
    immutableUpdate(selectorFn: (item: T) => boolean, upd: (actual: T) => T): T[];
    upsert(selectorFn: (item: T) => boolean, newValue: T);
    distinct(compareFn: (a: T, b: T) => boolean);
    findIndexOf(selectorFn: (item: T) => boolean);
    compareWith(compareTo: T[]): {
      isEqual: boolean;
      diff: T[];
    };
    immutableMove(currentIndex: number, newIndex: number);
  }
}

Array.prototype.compareWith = function (compareTo) {
  const diff = this.filter((x) => !compareTo.includes(x)).concat(compareTo.filter((x) => !this.includes(x)));

  return {
    isEqual: diff.length === 0,
    diff,
  };
};

Array.prototype.toObject = function (mapFn) {
  let result = {};

  this.forEach((entry) => {
    const kv = mapFn(entry);
    result[kv.key] = kv.value;
  });

  return result;
};

Array.prototype.toObjectNumberKey = function (mapFn) {
  let result = {};

  this.forEach((entry) => {
    const kv = mapFn(entry);
    result[kv.key] = kv.value;
  });

  return result;
};
Array.prototype.immutableMove = function (old_index, new_index) {
  const result = [...this];

  if (new_index >= result.length) {
    var k = new_index - result.length + 1;
    while (k--) {
      result.push(undefined);
    }
  }
  result.splice(new_index, 0, result.splice(old_index, 1)[0]);
  return result;
};
Array.prototype.findIndexOf = function (selectorFn) {
  const item = this.find(selectorFn);
  if (!item) {
    return -1;
  }

  return this.indexOf(item);
};
Array.prototype.groupBy = function (mapper, equalityFunction) {
  const result = [];

  this.forEach((item) => {
    const key = mapper(item);

    const existingGroup = result.find((r) => {
      if (equalityFunction) {
        return equalityFunction(r.key, key);
      }

      return r.key === key;
    });
    if (existingGroup) {
      existingGroup.items.push(item);
    } else {
      result.push({
        key,
        items: [item],
      });
    }
  });

  return result;
};

Array.prototype.last = function () {
  if (this.length === 0) {
    return undefined;
  }

  return this[this.length - 1];
};

Array.prototype.first = function () {
  if (this.length === 0) {
    return undefined;
  }

  return this[0];
};

Array.prototype.leftJoin = function (outer, innerFn, outerFn, projectionFn) {
  const result = [];
  this.forEach((innerItem) => {
    const innerKey = innerFn(innerItem);

    const outerItem = outer.find((o) => outerFn(o) === innerKey);

    result.push(projectionFn(innerItem, outerItem));
  });

  return result;
};

Array.prototype.immutableUpsert = function (keyFn, newItem) {
  const existingItem = this.find((o) => keyFn(o) === keyFn(newItem));

  if (existingItem) {
    const index = this.indexOf(existingItem);

    return Object.assign([], this, {
      [index]: newItem,
    });
  }

  return [...this, newItem];
};

Array.prototype.immutableUpsertByRef = function (cmpFn, newItem) {
  const existingItem = this.find((o) => cmpFn(o));

  if (existingItem) {
    const index = this.indexOf(existingItem);

    return Object.assign([], this, {
      [index]: newItem,
    });
  }

  return [...this, newItem];
};

Array.prototype.immutableReplaceByIndex = function (index, newItem) {
  return Object.assign([], this, {
    [index]: newItem,
  });
};

Array.prototype.immutableDeleteByIndex = function (index) {
  return [...this.slice(0, index), ...this.slice(index + 1)];
};

Array.prototype.immutableDelete = function (findFn) {
  const existingItem = this.find((o) => findFn(o));

  if (existingItem) {
    const index = this.indexOf(existingItem);

    return [...this.slice(0, index), ...this.slice(index + 1)];
  }

  return this;
};

Array.prototype.toMap = function (keyFn) {
  const map = new Map();
  this.forEach((item) => {
    const key = keyFn(item);
    if (map.has(key)) {
      map.set(key, []);
    }

    map.get(key).push(item);
  });

  return map;
};

Array.prototype.immutableUpsert = function (selectorFn, value) {
  const currentValue = this.find(selectorFn);

  if (currentValue) {
    const index = this.indexOf(currentValue);
    if (index != null) {
      this[index] = value;
    }
    return this.immutableReplaceByIndex(index, value);
  } else {
    return [...this, value];
  }
};

Array.prototype.immutableUpdate = function (selectorFn, upd) {
  const currentValue = this.find(selectorFn);

  if (currentValue) {
    const index = this.indexOf(currentValue);
    return this.immutableReplaceByIndex(index, upd(currentValue));
  } else {
    return this;
  }
};

Array.prototype.upsert = function (selectorFn, value) {
  const currentValue = this.find(selectorFn);

  if (currentValue) {
    const index = this.indexOf(currentValue);
    if (index != null) {
      this[index] = value;
    }
  } else {
    this.push(value);
  }
};

Array.prototype.distinct = function (compareFn) {
  const result = [];

  this.forEach((x) => {
    if (!result.find((y) => compareFn(x, y))) {
      result.push(x);
    }
  });

  return result;
};

export {};
